<template>
  <div class="c-audio-le u-mb" :class="`c-audio-le--${unitSlug}`">
    <UnitHeader
      v-if="unitSlug"
      :unitSlug="unitSlug"
      type="audiobook"
      :element="audio.name"
    />

    <div class="c-audio-le__wrapper">
      <VHeading v-if="audio.name" header="h1" class="c-audio-le__title">
        <AudioIconBig class="c-audio-le__title-icon" />
        {{ audio.name }}
      </VHeading>

      <AudioElement :content="audio" />

      <div v-if="audio.description" v-md-html="audio.description"></div>

      <FHButton
        v-if="audio.audioText"
        defaultStyles
        @click.native="showTranscript = !showTranscript"
        >{{ $t('VIDEO_TRANSCRIPT') }}</FHButton
      >
      <div
        v-show="showTranscript"
        class="c-audio-le__audio-text"
        v-md-html="audio.audioText"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchSH } from '@/api'
import { componentNameNormalize } from '@/utils'
import AudioIconBig from '@/assets/svg/icon_audio_big'

import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import UnitHeader from '@/components/UnitHeader'
import AudioElement from '@/components/ComponentLearningElements/AudioElement'

export default {
  components: {
    AudioIconBig,
    UnitHeader,
    VHeading,
    AudioElement,
    FHButton,
  },

  setup(props, ctx) {
    const audio = ref({})
    const audioSlug = computed(
      () => ctx.root.$options.router.history.current.params.audioSlug,
    )
    const unitSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )
    const showTranscript = ref(false)

    onMounted(async () => {
      audio.value = await fetchSH('audio-types/' + audioSlug.value)
      //so we can use same LE component
      audio.value.audio_type = audio.value
    })

    return {
      audio,
      componentNameNormalize,
      unitSlug,
      showTranscript,
    }
  },
}
</script>

<style lang="scss">
.c-audio-le {
  min-height: calc(100vh - 13rem);
  &--echo-1-identity {
    .c-unit__header {
      background-color: $unit-identity-bg;
    }
  }
  &--echo-2-english-around-the-world {
    .c-unit__header {
      background-color: $unit-english-bg;
    }
  }
  &--echo-3-citizenship {
    .c-unit__header {
      background-color: $unit-citizenship-bg;
    }
  }
  &--echo-4-indigenous-peoples {
    .c-unit__header {
      background-color: $unit-indigenous-bg;
    }
  }
  &--echo-my-grammar {
    .c-unit__header {
      background-color: $unit-grammar-bg;
    }
  }

  .c-unit__header {
    @include bp-down($tablet) {
      display: none;
    }
  }
}

.c-audio-le__wrapper {
  width: 100%;
  max-width: $article-width;
  margin: 0 auto;
  @include bp-down($tablet) {
    padding: 0 1.25rem;
    width: initial;
  }

  p {
    @include bp-down($tablet) {
      font-size: 1rem;
    }
  }
}
.c-audio-le__title-icon {
  vertical-align: middle;
}

.c-audio-le__title h1 {
  @include bp-down($tablet) {
    font-size: 1.5rem;
  }
}

.c-audio-le__aims {
  list-style: none none;
  padding: 0;
  margin: 0;
}
.c-audio-le__aim {
  padding: 0;
}

.c-audio-le__tags {
  font-weight: 700;
}
</style>
<template>
  <div class="c-search-tile" :class="{ 'c-search-tile--has-image': cover }">
    <router-link class="c-search-tile__link" :to="to">
      <div
        class="c-search-tile__image"
        :style="cover ? `background-image: url(${cover.url});` : ''"
      >
        <div class="c-search-tile__image-box">
          <AudioIcon class="c-search-tile__icon c-search-tile__icon--audio" />
          <VideoIcon class="c-search-tile__icon c-search-tile__icon--video" />
          <TextIcon class="c-search-tile__icon c-search-tile__icon--text" />
          <LessonPlanIcon class="c-search-tile__icon c-search-tile__icon--lp" />
          <div class="c-search-tile__image-text">{{ type }}</div>
        </div>
      </div>
      <div class="c-search-tile__content">
        <div class="c-search-tile__description" v-md-html="description"></div>
        <div v-if="fullTags.length" class="c-search-tile__tags">
          <span
            v-for="tag in fullTags"
            :key="`${type}${tag.id}`"
            class="c-search-tile__tag"
          >
            #{{ tag.label }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import VideoIcon from '@/assets/svg/Icon_Video'
import AudioIcon from '@/assets/svg/Icon_Audio'
import TextIcon from '@/assets/svg/Icon_Text'
import LessonPlanIcon from '@/assets/svg/Icon_Lessonplan'

export default {
  name: 'SearchTile',

  components: {
    AudioIcon,
    VideoIcon,
    TextIcon,
    LessonPlanIcon,
  },

  props: {
    to: String,
    image: String,
    type: String,
    tags: Array,
    description: String,
  },

  setup(props) {
    const cover = ref(null)
    const fullTags = ref([])

    onMounted(() => {
      if (props.image) {
        const coverURL = props.image
        const param = 'w_350,c_scale'
        cover.value = {
          url: addCloudinaryUrlParams(coverURL, param),
        }
      }
    })

    return {
      cover,
      fullTags,
    }
  },
}
</script>

<style lang="scss">
.c-search-tile {
  width: calc(33% - 0.25rem);

  @include bp-down($search-items-max-width) {
    width: calc(50% - 0.25rem);
  }
  @include bp-down($small) {
    width: 100%;
  }
}

.c-search-tile__image {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  background: $color-very-light-grey-2;
  background-size: cover;
  background-position: center;

  @include bp-down($small) {
    position: initial;
    padding-bottom: 0;
    height: auto;
  }
}

.c-search-tile__image-text {
  font-size: 1rem;
  font-weight: 300;
  padding: 3px 10px;
}

.c-search-tile__image-box {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: $tool-tile-bg;

  @include bp($small) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: start;
  }

  .c-search-tile--has-image & {
    right: 0;
    background: $color-brand;
  }
}

.c-search-tile__description {
  padding: 0 1rem;
  p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}

.c-search-tile__tags {
  font-weight: 700;
}

.c-search-tile__link {
  text-decoration: none;
  color: $color-black;
}

.c-search-tile__icon {
  display: none;
  min-width: 1.5rem;
  @include bp-down($small) {
    order: 1;
  }
}
</style>

<template>
  <div :class="`c-audiobook c-audiobook--${unitSlug} u-py`">
    <!-- audiobook menu on the left -->
    <aside class="c-audiobook__col u-py">
      <ul v-if="units.length" class="c-audiobook__unit-list">
        <li
          v-for="(unit, i) in units"
          :key="unit.id"
          class="c-audiobook__unit-list-item"
        >
          <FHButton
            :href="`/audio/${unit.slug}`"
            defaultStyles
            class="c-audiobook__unit-link"
            :class="{
              'c-audiobook__unit-link--active': unit.slug === unitSlug,
            }"
          >
            {{ i + 1 }} {{ unit.name }}
          </FHButton>
        </li>
      </ul>
    </aside>
    <!-- on the right breadcrumbs, title, list of audiobook unit -->
    <main class="c-audiobook__col u-px--3">
      <Breadcrumbs
        class="c-audiobook__breadcrumbs u-px u-py"
        v-if="crumbs"
        :crumbs="crumbs"
      />

      <VHeading header="h1" class="c-audiobook__unit-title u-px">
        <span v-if="unitIndex" class="c-audiobook__unit-title-index"
          >{{ unitIndex }}
        </span>
        {{ unitName }}
      </VHeading>
      <!-- link to single audio -->
      <div class="c-audiobook__list">
        <SearchTile
          v-for="audio in audios"
          :key="audio.id"
          :to="`/audio/${unitSlug}/${audio.slug}`"
          :type="audio.name"
          description=""
        />
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchSH } from '@/api'
import { fetchCourseUnits, fetchUnitBySlug } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'

import VHeading from '@forlagshuset/v-heading'
import Breadcrumbs from '@forlagshuset/v-breadcrumbs'
import FHButton from '@forlagshuset/v-button'
import SearchTile from '@/components/SearchTile'

export default {
  components: {
    Breadcrumbs,
    VHeading,
    FHButton,
    SearchTile,
  },

  setup(props, ctx) {
    const audios = ref([])
    const unitSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )
    const unitName = ref('')
    const unitIndex = ref(0)
    const crumbs = ref([])
    const units = ref([])
    const unitTag = ref({})

    onMounted(async () => {
      //find index of the unit
      const unit = await fetchUnitBySlug(unitSlug.value)
      unitName.value = unit.name
      unitTag.value = unit.tags[0].id

      units.value = (await fetchCourseUnits('echo')).filter(
        unit => !unit.teacherUnit,
      )

      const fetchTag = await fetchSH('tag-types/' + unitTag.value)
      audios.value = fetchTag.audios

      //add unit
      crumbs.value.push({
        type: 'audio',
        name: unitName.value,
        slug: unitSlug.value,
      })
    })

    return {
      audios,
      crumbs,
      componentNameNormalize,
      unitSlug,
      unitName,
      unitIndex,
      units,
    }
  },
}
</script>

<style lang="scss">
.c-audiobook {
  min-height: calc(100vh - 17rem);
  &--echo-1-identity {
    .c-search-tile__image {
      background-color: $unit-identity-bg;
    }
  }
  &--echo-2-english-around-the-world {
    .c-search-tile__image {
      background-color: $unit-english-bg;
    }
  }
  &--echo-3-citizenship {
    .c-search-tile__image {
      background-color: $unit-citizenship-bg;
    }
  }
  &--echo-4-indigenous-peoples {
    .c-search-tile__image {
      background-color: $unit-indigenous-bg;
    }
  }
  &--echo-my-grammar {
    .c-search-tile__image {
      background-color: $unit-grammar-bg;
    }
  }
}

.c-audiobook {
  display: flex;
  flex-direction: row;
  max-width: $large;
  @include bp-down($medium) {
    flex-direction: column;
  }
}

.c-audiobook__col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 4;

  &:first-child {
    flex: 2;
  }
  &:last-child {
    max-width: calc(1024px - 33%);
  }
}

.c-audiobook__breadcrumbs {
  @include bp-down($medium) {
    display: none;
  }
  .c-breadcrumbs__home {
    text-decoration: none;
    color: $color-black;
    .c-breadcrumbs__home {
      fill: none;
      width: 0;
    }
    &:before {
      content: 'Home';
      vertical-align: super;
    }
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
.c-audiobook__unit-list {
  list-style: none none;
  padding: 0 1rem !important;
  margin: 0;
}
.c-audiobook__unit-list-item {
  padding-left: 0;
  &:before {
    display: none;
  }
}
.c-audiobook__unit-link {
  display: block;
  background: none;
  border: none;
  &--active {
    font-weight: 700;
    background-color: $color-brand;
  }
}
.c-audiobook__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
  @include bp-down($medium) {
    justify-content: center;
  }
  .c-search-tile {
    width: auto;
    margin: 0;
    .c-search-tile__image {
      flex: 0 0 50%;
      padding: 0;
      height: 8rem;
      width: 8rem;
    }
    .c-search-tile__audio-icon {
      display: block;
    }
    .c-search-tile__description {
      margin: 0;
    }
  }
}
</style>
